body {
    margin: 0;
    font-family: 'Pretendard', sans-serif;

    --gray98: #F5F5F5;
    --gray95: #F2F2F2;
    --gray90: #E6E6E6;
    --gray85: #D9D9D9;
    --gray75: #BFBFBF;
    --gray70: #B3B3B3;
    --gray60: #999999;
    --gray55: #8C8C8C;
    --gray50: #808080;
    --gray45: #737373;
    --gray40: #666666;
    --gray30: #4D4D4D;
    --gray25: #404040;
}

* {
    overscroll-behavior: none;
    box-sizing: border-box;
    font-family: 'Pretendard', sans-serif;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
}

a, img {
    -webkit-user-drag: none;
}